import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "@/views/HomePage.vue";
import TestPage from "@/views/TestPage.vue";
import WebLKPage from "@/views/WebLKPage.vue";
import NewUser from "@/views/NewUser.vue";
import TextErrors from "@/views/TextErrors.vue";
import UsSchool from "@/views/UsSchool";

const routes = [
  {
    path: '/:lang',
    name: 'home',
    component: HomePage
  },
  {
    path: '/:lang/test',
    name: 'test',
    component: TestPage
  },
  {
    path: '/:lang/web-version',
    name: 'web-version',
    component: WebLKPage
  },
  {
    path: '/:lang/new-user',
    name: 'new-user',
    component: NewUser
  },
  {
    path: '/:lang/text-errors',
    name: 'text-errors',
    component: TextErrors
  },
  {
    path: '/:lang/us-school',
    name: 'us-school',
    component: UsSchool
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
