<template>
  <picture class="b-back" @click="$emit('back')">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M18 10L12 16L18 22" stroke="#18242F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </picture>
</template>

<script>
export default {
  name: 'BaseBackPopup',
  emits: ['back'],
  props: {
    fill: {
      type: String,
      default: 'white'
    },
    fillBg: {
      type: String,
      default: 'gray'
    },
    opacity: {
      type: String,
      default: '0.2'
    }
  },
}
</script>

<style scoped>

.b-back {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 3;
}
</style>
