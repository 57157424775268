import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from '@/plugins/i18n'
import { vuetify } from '@/plugins/vuetify'
import { createHead } from '@vueuse/head'

const head = createHead()
createApp(App).use(store).use(router).use(vuetify).use(i18n).use(head).mount('#app')
