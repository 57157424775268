export let config = {
    title: 'title',
    meta: {
        title: 'title',
        description: 'intro',
        og: {
            title: 'title',
            description: 'intro'
        }
    },
    analytics: {
        name: 'weblk-dec',
        openSurvey: 'surveys_open',
        sendAnswers: 'surveys_send_answers',
        closeSurvey: 'surveys_close'
    },
    intro: {
        text: 'intro',
        btn: 'button_next',
        cancel: 'button_cancel',
    },
    questions: [
        {
            type: "Close",
            required: true,
            props: {
                question: 'q1',
                variants: [
                    'q1_v1',
                    'q1_v2',
                    'q1_v3',
                    'q1_v4',
                    'q1_v5',
                ],
                oneAnswer: true,
                lastIsOtherOption: true
            }
        },
        {
            type: "Close",
            required: true,
            props: {
                question: 'q2',
                variants: [
                    'q2_v1',
                    'q2_v2',
                    'q2_v3',
                    'q2_v4',
                ],
                oneAnswer: false,
                lastIsOtherOption: true
            }
        },
        {
            type: "Close",
            required: true,
            props: {
                question: 'q3',
                variants: [
                    'q3_v1',
                    'q3_v2',
                    'q3_v3',
                    'q3_v4',
                ],
                oneAnswer: false,
                lastIsOtherOption: true
            }
        },
        {
            type: "Close",
            required: true,
            props: {
                question: 'q4',
                variants: [
                    'q4_v1',
                    'q4_v2',
                    'q4_v3',
                    'q4_v4',
                ],
                oneAnswer: true,
                lastIsOtherOption: false
            }
        },
        {
            type: "Open",
            required: false,
            props: {
                question: 'q5',
            }
        },
        {
            type: "Open",
            required: false,
            props: {
                question: 'q6',
            }
        },
    ],
    outro: {
        text: 'outro',
        btn: 'button_close',
        backUrl: `https://my.${window.location.host.includes('findmykids') ? 'findmykids.org' : 'gdemoideti.ru'}`
    },
    btnNext: 'button_next',
    btnBack: 'button_prev',
    btnEnd: 'button_next'
}