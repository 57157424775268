<script setup>
import { config } from "@/forms/us-school"
import { default as messages } from "@/assets/languages/forms/us-school/us-school.json"
</script>

<template>
  <Head>
    <title>{{ messages[$i18n.locale][config.meta.title] }}</title>
    <meta name="description" :content="messages[$i18n.locale][config.meta.description]">
    <meta property="og:title" :content="messages[$i18n.locale][config.meta.og.title]">
    <meta property="og:description" :content="messages[$i18n.locale][config.meta.og.description]">
  </Head>
  <BaseClosePopup :action="config.analytics.closeSurvey" :survey-name="config.analytics.name"/>
  <SurveyBuilder :structure="config" :t="messages[$i18n.locale]"/>
</template>

<script>
import SurveyBuilder from "@/components/SurveyBuilder.vue";
import { Head } from '@vueuse/head'
import BaseClosePopup from "@/components/ui/base/BaseClosePopup.vue";

export default {
  name: 'UsSchool',
  components: {
    SurveyBuilder,
    Head,
    BaseClosePopup
  },
  i18n: {
    messages: messages
  },
}
</script>
