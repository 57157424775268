export let config = {
  title: 'title',
  meta: {
    title: 'title',
    description: 'description',
    og: {
      title: 'title',
      description: 'description'
    }
  },
  analytics: {
    name: 'text-errors',
    openSurvey: 'surveys_open',
    sendAnswers: 'surveys_send_answers',
    closeSurvey: 'surveys_close'
  },
  intro: {
    skip: true,
    text: 'intro_t',
    btn: 'intro_b',
    cancel: 'b_cancel',
  },
  questions: [
    {
      type: "Close",
      required: true,
      props: {
        question: 'q_1',
        variants: [
          'q_1_v_1',
          'q_1_v_2'
        ],
        oneAnswer: true,
        lastIsOtherOption: true,
        inputLabel: 'q_1_i_l'
      }
    }
  ],
  outro: {
    text: 'outro_t',
    btn: 'outro_b',
    backUrl: `https://my.${window.location.host.includes('findmykids') ? 'findmykids.org' : 'gdemoideti.ru'}`
  },
  btnNext: 'b_next',
  btnBack: 'b_back',
  btnEnd: 'b_end'
}