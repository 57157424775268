import axios from "axios"

export const client = axios.create({
    baseURL: 'https://api.findmykids.org'
})

export default {
    sendAnalytics(data) {
        client.get('/service/funnelTrack/?method=service.funnelTrack&' + new URLSearchParams(data))
    }
}