import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

export const vuetify = createVuetify({
    components,
    directives,
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    blue: '#0085ff',
                    // gray: '#8B8484',
                    // dark: '#404040',
                    // background: '#D7DAF0'
                }
            },
        },
    },
    icons: {
        defaultSet: 'mdi',
    },
})