import { createStore } from 'vuex'
import get from 'lodash/get'
import {getMobileOperatingSystem, isMobile, isWebView} from "@/utils/utils";
import { default as api } from "@/api/api"

export default createStore({
  state: {
    getParams: null,
    lang: '',
    sessionNumber: ''
  },
  getters: {
  },
  mutations: {
    setGetParams (state, value) {
      state.getParams = value
      state.sessionNumber = crypto.randomUUID()
    },
    setLang (state, value) {
      state.lang = value
    },
  },
  actions: {
    /**
     * Отправка аналитики
     * @param data.action
     * @param data.addData
     * @return {*}
     */
    funnelTrack ({ state }, data) {
      let params = {
        action: data.action,
        deviceUid: get(state.getParams, 'deviceUid', ''),
        appVersion: get(state.getParams, 'appVersion', ''),
        deviceType: get(state.getParams, 'deviceType', ''),
        env: get(state.getParams, 'env', ''),
        sessionNumber: get(state.getParams, 'sessionNumber', state.sessionNumber),
        ipc: 1,
        addJson: JSON.stringify(Object.assign({}, {
          timestamp: + new Date(),
          typeScreen: isMobile() ? 'mobile' : 'desktop',
          typeDevice: isMobile() ? getMobileOperatingSystem() : 'desktop',
          lang: state.lang,
          url: window.location.href,
          userId: get(state.getParams, 'userId', ''),
          billingState: get(state.getParams, 'state', ''),
          isWebView: isWebView,
        }, data.addData))
      }
      return api.sendAnalytics(params)
    },
  },
  modules: {
  }
})
