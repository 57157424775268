export let config = {
  title: 'title',
  meta: {
    title: 'title',
    description: 'description',
    og: {
      title: 'title',
      description: 'description'
    }
  },
  analytics: {
    name: 'test',
    openSurvey: 'surveys_open',
    sendAnswers: 'surveys_send_answers',
    closeSurvey: 'surveys_close'
  },
  intro: {
    text: 'intro_t',
    btn: 'intro_b',
    cancel: 'b_cancel',
  },
  questions: [
    {
      type: "Open",
      required: true,
      props: {
        question: 'q_1',
        inputLabel: 'q_1_i_l'
      }
    },
    {
      type: "Close",
      required: false,
      props: {
        question: 'q_1',
        variants: [
          'q_1_v_1',
          'q_1_v_2'
        ],
        oneAnswer: true,
        lastIsOtherOption: false
      }
    },
    {
      type: "Close",
      required: true,
      props: {
        question: 'q_2',
        variants: [
          'q_2_v_1',
          'q_2_v_2',
          'q_2_v_3'
        ],
        oneAnswer: false,
        lastIsOtherOption: false
      }
    },
    {
      type: "Close",
      required: true,
      props: {
        question: 'q_3',
        variants: [
          'q_3_v_1',
          'q_3_v_2'
        ],
        oneAnswer: true,
        lastIsOtherOption: true,
        inputLabel: 'q_3_i_l'
      }
    },
    {
      type: "Close",
      required: true,
      props: {
        question: 'q_4',
        variants: [
          'q_4_v_1',
          'q_4_v_2',
          'q_4_v_3'
        ],
        oneAnswer: false,
        lastIsOtherOption: true,
        inputLabel: 'q_4_i_l'
      }
    }
  ],
  outro: {
    text: 'outro_t',
    btn: 'outro_b',
    backUrl: 'https://my.findmykids.org'
  },
  btnNext: 'b_next',
  btnBack: 'b_back',
  btnEnd: 'b_end'
}