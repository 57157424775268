<template>
  <div class="answers-block">
    <select v-model="answer">
      <option v-for="(variant, index) in variants" :key="index" :id="index+'_variant'" :value="t[variant]">
        {{t[variant]}}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectQuestion",
  props: {
    question: String,
    variants:  {
      type: Array
    },
    oneAnswer: {
      Boolean,
      default: true
    },
    t: {
      type: Object
    },
    inputLabel: String,
    lastIsOtherOption: Boolean,
    modelValue: {
      type: [Array, String]
    },
    openAnswerValue: {
      type: String
    },
  },
  computed: {
    answer: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },
  }
}
</script>

<style scoped>
.answers-block {
  margin-top: 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.16px;
}

select {
  width: 100%;
  border: 2px solid #ADBBCC;
  padding: 16px;
  border-radius: 8px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGNsaXAtcnVsZT0nZXZlbm9kZCcgZD0nTTQuMTg2MzQgOS40MTg3NkM0LjUwNzM1IDguOTY5MzUgNS4xMzE5IDguODY1MjYgNS41ODEzMiA5LjE4NjI3TDEyLjAwMDEgMTMuNzcxMUwxOC40MTg4IDkuMTg2MjdDMTguODY4MyA4Ljg2NTI2IDE5LjQ5MjggOC45NjkzNSAxOS44MTM4IDkuNDE4NzZDMjAuMTM0OCA5Ljg2ODE3IDIwLjAzMDcgMTAuNDkyNyAxOS41ODEzIDEwLjgxMzdMMTIuNTgxMyAxNS44MTM3QzEyLjIzMzYgMTYuMDYyMSAxMS43NjY1IDE2LjA2MjEgMTEuNDE4OCAxNS44MTM3TDQuNDE4ODQgMTAuODEzN0MzLjk2OTQzIDEwLjQ5MjcgMy44NjUzNCA5Ljg2ODE3IDQuMTg2MzQgOS40MTg3NlonIGZpbGw9JyMxRjI1MkUnLz48L3N2Zz4K");
  background-position: right 16px top 50%, 0 0;
}
</style>