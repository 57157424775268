export let config = {
    title: 'title',
    meta: {
        title: 'title',
        description: 'description',
        og: {
            title: 'title',
            description: 'description'
        }
    },
    analytics: {
        name: 'us-school',
        openSurvey: 'surveys_open',
        sendAnswers: 'surveys_send_answers',
        closeSurvey: 'surveys_close'
    },
    intro: {
        text: 'intro_t',
        btn: 'intro_b',
        cancel: 'b_cancel',
    },
    questions: [
        {
            type: "Open",
            required: true,
            props: {
                question: 'q_1',
                inputLabel: 'q_1_i_l'
            }
        },
        {
            type: "Select",
            required: false,
            props: {
                question: 'q_2',
                variants: Array.from(new Array(50), (x, i) => `q_2_v${i+1}`),
                oneAnswer: true,
                lastIsOtherOption: false
            }
        },
        {
            type: "Open",
            required: true,
            props: {
                question: 'q_3',
            }
        },
        {
            type: "Open",
            required: true,
            props: {
                question: 'q_4',
            }
        },
        {
            type: "Close",
            required: true,
            props: {
                question: 'q_5',
                variants: [
                    'q_5_v1',
                    'q_5_v2',
                ],
                oneAnswer: true,
                lastIsOtherOption: true,
            }
        },
        {
            type: "Open",
            required: true,
            props: {
                question: 'q_6',
            }
        },
    ],
    outro: {
        text: 'outro_t',
        btn: 'outro_b',
        backUrl: 'https://my.findmykids.org'
    },
    btnNext: 'b_next',
    btnBack: 'b_back',
    btnEnd: 'b_end'
}