<template>
  <textarea class="open-answer"
            v-model="openAnswer" :placeholder="t[inputLabel]"></textarea>
</template>

<script>
export default {
  name: 'OpenQuestion',
  props: {
    question: String,
    inputLabel: String,
    t: {
      type: Object
    },
    modelValue: {
      type: [Array, String]
    },
    openAnswerValue: {
      type: String
    }
  },
  computed: {
    answer: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },
    openAnswer: {
      get() {
        return this.openAnswerValue
      },
      set(newValue) {
        this.$emit('update:openAnswerValue', newValue)
      }
    }
  }
}
</script>

<style scoped>
.open-answer {
  display: flex;
  height: 126px;
  padding: 16px 16px 0 16px;
  align-items: flex-start;
  gap: 8px;
  margin: 20px auto auto auto;
  border-radius: 8px;
  border: 2px solid #ADBBCC;
  width: 100%;
}
</style>