<template>
  <div class="answers-block">
    <div class="variant" v-for="(variant, index) in variants" :key="index">
      <input :type="oneAnswer ? 'radio' : 'checkbox'" :id="index+'_variant'" v-model="answer" :value="t[variant]"
             @click="(lastIsOtherOption && variant === variants[variants.length-1]) ? scrollToBottom() : undefined"/>
      <label :for="index+'_variant'">
        <img alt="" :src="getIcon(t[variant])" />
        <span> {{ t[variant] }} </span>
      </label>
    </div>
  </div>

  <OpenQuestion v-show="isOtherOption()" @click="scrollToBottom"
                v-model:open-answer-value="openAnswer" :t="t" :input-label="inputLabel" />
</template>

<script>
import radio from "@/assets/img/radio-icon.svg"
import radioOn from "@/assets/img/radio-on-icon.svg"
import checkBox from "@/assets/img/checkbox.svg"
import checkBoxOn from "@/assets/img/checkbox-on-icon.svg"
import OpenQuestion from "@/components/schemas/OpenQuestion.vue";
export default {
  name: 'CloseQuestion',
  components: {
    OpenQuestion
  },
  data() {
    return {
      radioIcon: radio,
      radioOnIcon: radioOn,
      checkBoxIcon: checkBox,
      checkBoxOnIcon: checkBoxOn,
    }
  },
  props: {
    question: String,
    variants:  {
      type: Array
    },
    oneAnswer: {
      Boolean,
      default: true
    },
    t: {
      type: Object
    },
    inputLabel: String,
    lastIsOtherOption: Boolean,
    modelValue: {
      type: [Array, String]
    },
    openAnswerValue: {
      type: String
    },
  },
  computed: {
    answer: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        if (!this.isOtherOption()) {
          this.$emit('update:openAnswerValue', '')
        }
        this.$emit('update:modelValue', newValue)
      },
    },
    openAnswer: {
      get() {
        return this.openAnswerValue
      },
      set(newValue) {
        this.$emit('update:openAnswerValue', newValue)
      }
    }
  },
  methods: {
    isOtherOption () {
      if (this.oneAnswer && this.lastIsOtherOption
          && this.modelValue === this.t[this.variants[this.variants.length-1]]) {
        return true
      }
      else if (!this.oneAnswer && this.lastIsOtherOption) {
        let otherOption = this.t[this.variants[this.variants.length-1]]
        for (let i = 0; i < this.answer.length; i++) {
          if (this.answer[i] === otherOption) {
            return true
          }
        }
      }
      return false
    },
    getIcon (value) {
      if (this.oneAnswer && this.modelValue === value) {
        return this.oneAnswer ? this.radioOnIcon : this.checkBoxOnIcon
      }
      for (let i = 0; i < this.answer.length; i++) {
        if (this.answer[i] === value) {
          return this.oneAnswer ? this.radioOnIcon : this.checkBoxOnIcon
        }
      }
      return this.oneAnswer ? this.radioIcon : this.checkBoxIcon
    },
    scrollToBottom () {
      setTimeout(() => {
        scrollTo(0, document.body.scrollHeight)
      }, 100)
    }
  }
}
</script>

<style scoped>
.answers-block {
  margin-top: 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.16px;
}
.variant {
  display: flex;
  margin-bottom: 24px;
}
.variant label {
  display: flex;
  position: relative;
}
.variant label span {
  margin: auto auto auto 36px;
}
.variant label img {
  top: 0;
  position: absolute;
}
input[type="radio"]{
  -webkit-appearance: none;
  height: 0;
  width: 0;
}
input[type="checkbox"]{
  -webkit-appearance: none;
  height: 0;
  width: 0;
}
</style>