<template>
  <div class="content" v-show="structure">
    <div v-if="isIntro" class="intro-block">
      <IntroComponent
          :title="t[structure.title]"
          :text="t[structure.intro.text]"
          :btn-text="t[structure.intro.btn]"
          :cancel-text="t[structure.intro.cancel]"
          @next-block="nextBlock"
          @cancel="cancel"
      />
    </div>

      <div  v-for="(component, index) in structure.questions" :key="index" v-else-if="isQuestions">
        <div v-if="currentQuestion === index+1">
          <BaseBackPopup @back="prevBlock" v-show="checkIntro()"/>
          <div class="question-block">
            <div class="counter">{{ currentQuestion >= realCountQuestions() ? realCountQuestions() : currentQuestion }} / {{ realCountQuestions() }}</div>
            <div class="question">
              <div class="question-text">
                {{ t[component.props.question] }}
              </div>
              <component :is="getComponentType(component.type)"
                         v-model:model-value="answers[index].answer"
                         v-model:open-answer-value="answers[index].openAnswer"
                         v-bind="Object.assign({ t: t }, component.props)"/>
            </div>
          </div>
          <div class="btn-block">
            <div class="btn-gradient"></div>
            <div class="btn-wrap pb-40">
              <button :disabled="isRequiredQ(index)" v-if="isLastQuestion" :class="isRequiredQ(index) ? 'btn disabled' : 'btn'" @click="sendCompletedSurvey">{{ t[structure.btnEnd] }}</button>
              <button :disabled="isRequiredQ(index)" v-else :class="isRequiredQ(index) ? 'btn disabled' : 'btn'" @click="nextBlock">{{ t[structure.btnNext] }}</button>
            </div>
          </div>
        </div>
      </div>

    <div v-else class="outro-block">
      <OutroComponent
          :text="t[structure.outro.text]"
          :btn-text="t[structure.outro.btn]"
          @back-to-app="backToApp"
      />
    </div>
  </div>
</template>

<script>
import OpenQuestion from "@/components/schemas/OpenQuestion.vue";
import CloseQuestion from "@/components/schemas/CloseQuestion.vue";
import SelectQuestion from "@/components/schemas/SelectQuestion";
import IntroComponent from "@/components/ui/IntroComponent.vue";
import OutroComponent from "@/components/ui/OutroComponent.vue";
import {mapActions} from "vuex";
import BaseBackPopup from "@/components/ui/base/BaseBackPopup.vue";
import {closeSurvey} from "@/utils/utils";
export default {
  name: 'SurveyBuilder',
  components: {
    BaseBackPopup,
    OpenQuestion,
    CloseQuestion,
    IntroComponent,
    OutroComponent,
    SelectQuestion
  },
  data() {
    return {
      currentQuestion: 0,
      answers: [],
      completed: false
    }
  },
  props: {
    structure: {
      type: Object
    },
    t: {
      type: Object
    }
  },
  mounted() {
    this.restoreState()
    this.funnelTrack({
      action: this.structure.analytics.openSurvey,
      addData: { surveyName: this.structure.analytics.name }
    }).finally(() => {
      if (this.structure.intro.skip && !localStorage[this.structure.analytics.name]) {
        this.nextBlock()
      }
      this.structure.questions.forEach((q) => {
        this.answers.push({question: this.t[q.props.question], answer: [], openAnswer: ''})
      })
    })
  },
  computed: {
    isIntro () {
      return this.currentQuestion === 0 && !this.structure.intro.skip
    },
    isQuestions () {
      return this.currentQuestion > 0 && this.currentQuestion <= this.structure.questions.length
    },
    isLastQuestion () {
      return this.currentQuestion === this.structure.questions.length
    }
  },
  methods: {
    ...mapActions(['funnelTrack']),
    realCountQuestions () {
      if (!this.structure.len) {
        return this.structure.questions.length
      }
      return this.structure.len[this.t.locale] ?? (this.structure.len.all ?? this.structure.questions.length)
    },
    checkIntro() {
      return !(this.currentQuestion === 1 && this.structure.intro.skip)
    },
    isRequiredQ(index) {
      return !!(this.structure.questions[index].required && (!this.answers[index].answer || this.answers[index].answer.length === 0 && !this.answers[index].openAnswer))
    },
    cancel () {
      this.funnelTrack({
        action: this.structure.analytics.closeSurvey,
        addData: { surveyName: this.structure.analytics.name, backUrl: this.structure.outro.backUrl }
      }).finally(() => {
        closeSurvey(this.structure.intro.backUrl)
      })
    },
    nextBlock () {
      if (this.isQuestions) {
        this.sendAnswer(this.currentQuestion-1, this.completed)
      }
      do {
        this.currentQuestion++
      }
      while (this.isQuestions && !this.t[this.structure.questions[this.currentQuestion-1].props.question])
      this.saveState()
      window.scrollTo(0,0)
    },
    prevBlock () {
      do {
        this.currentQuestion--
      }
      while (this.isQuestions && !this.t[this.structure.questions[this.currentQuestion-1].props.question])
      window.scrollTo(0,0)
    },
    async sendCompletedSurvey() {
      this.nextBlock()
      this.completed = true
      await this.sendAnswers()
    },
    sendAnswer (index, completed) {
      const answer = this.answers[index]
      if (this.checkAnswer(answer)) {
        this.funnelTrack({
          action: this.structure.analytics.sendAnswers,
          addData: {surveyName: this.structure.analytics.name, answer: answer, surveyCompleted: completed}
        })
      }
    },
    sendAnswers () {
      this.funnelTrack({
        action: this.structure.analytics.sendAnswers,
        addData: { surveyName: this.structure.analytics.name, answers: this.filterAnswers(), surveyCompleted: this.completed }
      }).then(() => {
        this.clearState()
      })
    },
    filterAnswers () {
      const result = []
      this.answers.forEach((answer) => {
        if (this.checkAnswer(answer)) {
          result.push(answer)
        }
      })
      return result
    },
    getComponentType(type) {
      return type + 'Question'
    },
    backToApp() {
      this.funnelTrack({
        action: this.structure.analytics.closeSurvey,
        addData: { surveyName: this.structure.analytics.name, backUrl: this.structure.outro.backUrl }
      }).finally(() => {
        closeSurvey(this.structure.outro.backUrl)
      })
    },
    checkAnswer(answer) {
      return answer.answer.length !== 0 || answer.openAnswer.length !== 0
    },
    saveState() {
      const state = {
        currentQuestion: this.currentQuestion,
        answers: this.answers,
        completed: this.completed
      }
      localStorage.setItem(this.structure.analytics.name, JSON.stringify(state))
    },
    restoreState() {
      if (localStorage[this.structure.analytics.name]) {
        const state = JSON.parse(localStorage[this.structure.analytics.name])
        this.currentQuestion = state.currentQuestion
        this.answers = state.answers
        this.completed = state.completed
      }
    },
    clearState() {
      if (localStorage[this.structure.analytics.name]) {
        localStorage.removeItem(this.structure.analytics.name)
      }
    }
  }
}
</script>

<style scoped>
.content {
  min-height: 100vh;
  background-color: white;
  max-width: 768px;
  width: 100%;
  position: relative;
}
.intro-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.question-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto 32px auto 32px;
  padding-bottom: 150px;
}
.question {
  margin-top: 12px;
}
.counter {
  margin-top: 56px;
  color: #4A5464;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.16px;
}
.question-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.36px;
  color: #1F252E;
}
.outro-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
