<template>
  <picture class="b-close" @click="close" v-show="isWebviewPopup">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path :opacity="opacity" fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" :fill="fillBg"/>
      <path d="M22 10L16 16M16 16L10 22M16 16L22 22M16 16L10 10" :stroke="fill" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round"/>
    </svg>
  </picture>
</template>

<script>
  import {mapActions} from 'vuex'
  import {closeSurvey, isWebView, isWebviewIOS} from '@/utils/utils'

  export default {
    name: 'BaseClosePopup',
    props: {
      fill: {
        type: String,
        default: 'white'
      },
      fillBg: {
        type: String,
        default: 'gray'
      },
      action: {
        type: String,
        default: 'surveys_close'
      },
      surveyName: {
        type: String,
        default: 'test'
      },
      opacity: {
        type: String,
        default: '0.2'
      },
    },

    data () {
      return {
        isWebviewPopup: false
      }
    },

    mounted () {
      setTimeout(() => {
        this.isWebviewPopup = isWebView
      }, 0)
    },

    methods: {
      ...mapActions(['funnelTrack']),
      close () {
        this.funnelTrack({
          action: this.action,
          addData: { surveyName: this.surveyName }
        }).finally(() => {
          closeSurvey()
        })
      }
    }
  }
</script>

<style scoped>
.b-close {
  width: 32px;
  height: 32px;
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 999;
}
</style>
